$primary: #B2352E; /* MAIN COLOR */
$secondary: #3483CC; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

@import url('https://fonts.googleapis.com/css?family=Passion+One|Ruda|Shrikhand');
body {
	font-size: 14px;
}

.flash {
	display:none;
}

nav {
	z-index: 1000;
}
p {
	font-size: 17px;
}
.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 80px;
		display: flex;
		align-items: center;


		@media (max-width: 767px) {
		    height: 30px;
		    display: inline-block;
		}

	    &:hover {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    }
	    &:focus {
	    	color: #777;
	    }
	}
}

.navbar-right {
	margin-top: 0px;
}
 
.navbar-toggle {
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


a,a:hover,a:focus,a:active {
  outline: 0 !important;
}


@media(max-width: 767px) {
  .navbar-header a {
    float: left;
  } 
}

.modal-open {
  overflow: auto; 
  padding-right: 0px !important;
}
.banner {
	background: url(../img/banner.jpg) no-repeat;
	background-size: cover;
	height: 800px;
	background-position: 50% 50%;
	position: relative;
	h1 {
		position: absolute;
		top: 50%;
		left: 50%;
		// width: 75%;
		text-align: center;
		transform: translate(-50%,-50%);
		color: white;
		font-size: 5em;
		@media (max-width: 767px) {
			font-size: 3em;
		}
	}
	@media (max-width: 991px) {
		height: 600px;
	}
	@media (max-width: 767px) {
		height: 400px;
	}

}
.introBox {
	background: $primary;
	position: relative;
	top: -60px;
	@media (max-width: 991px) {
		top: 0;
		width: 100%;
	}
	h1 {
		font-size: 2em;
		color: white;
	}
	.secondary {
		background: $secondary;
		padding: 50px 15px;
		@media (max-width: 1024px) {
			padding: 20px 15px;
		}
	}
	.primary {
		padding: 50px 15px;
		@media (max-width: 1024px) {
			padding: 20px 15px;
		}
	}
	hr {
		width: 20%;
	}
}

.introTxt {
	position: relative;
	p {
		font-size: 17px;
		line-height: 1.5;
	}
	&::before {
		content: "Meet";
		position: absolute;
		bottom: -9%;
		left: 2%;
		font-size: 120px;
		color: rgba(0,0,0,0.1);
		font-family: 'Shrikhand', cursive;
		z-index: -1;
		@media (max-width: 767px) {
			font-size: 70px;
			bottom: 0;
			left: 30%;
		}
	}
}
p {
	font-family: 'Ruda', sans-serif;
}

h1,h2,h3 {
	font-family: 'Passion One', cursive;
	color: $secondary;
}

a.btn {
	@include btn1;
}

.pic1, .pic2 {
	max-width: 450px;
	width: 100%;
	padding: 10px;
	float: right;
	@media (max-width: 991px) {
		float: none;
		max-width: 100%;
	}
}

.sideImgBox {
	position: absolute;
	bottom: -30%;
	right: -18%;
	width: 60%;
	@media (max-width: 991px) {
		position: static;
		display: none;
	}
}
.midSectionTxtBox {
	background: white;
	padding: 40px;
	&::before {
		content: "Date";
		position: absolute;
		bottom: -9%;
		left: 0%;
		font-size: 120px;
		color: rgba(0,0,0,0.1);
		font-family: 'Shrikhand', cursive;
		@media (max-width: 767px) {
			font-size: 70px;
			bottom: 0;
			left: 30%;
		}
	}
}
.midSectionTxtBox2 {
	background: white;
	padding: 40px;
	font-size: 17px;
	&::before {
		content: "Love";
		position: absolute;
		bottom: -9%;
		left: 0%;
		font-size: 120px;
		color: rgba(0,0,0,0.1);
		font-family: 'Shrikhand', cursive;
		@media (max-width: 767px) {
			font-size: 70px;
			bottom: 0;
			left: 30%;
		}
	}
}

.banner2 {
	background: linear-gradient(
       rgba(52,131,204,0.6),
       rgba(52,131,204,0.6)
		),url(../img/banner2.jpg) no-repeat;
	background-size: cover;
	height: 350px;
	background-blend-mode: multiply;
	background-position: 50% 50%;
	position: relative;
	h1 {
		color: white;
		top: 50%;
		left: 50%;
		font-size: 3em;
		position: absolute;
		text-align: center;
		transform: translate(-50%,-50%);
		font-family: 'Shrikhand', cursive;
	}
}

i.fa {
	font-size: 6em;
}

.middle {
	position: relative;
	top: -35px;
	@media (max-width: 767px) {
		top: 0;
	}
}
.padTopBtm {
	padding: 100px 0;
}
.bg {
	background: $secondary;
	h2 {
		color: white;
	}
	i.fa, p {
		color: white;
	}
	.col-xs-4 {
		@media (max-width: 550px) {
			width: 100%;
		}
	}
}

.logo {
	max-width: 225px;
	margin-top: 10px;
	@media (max-width: 767px) {
		max-width: 150px;
		width: 100%;
	}
}

.pic1Contain, .pic2Contain {
	&.col-xs-6 {
		@media (max-width: 500px) {
			width: 100%;
		}
	}
}

footer a:hover, footer a:focus {
	outline: 0;
	text-decoration: none;
	color: white;
}